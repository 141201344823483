import { Controller } from '@hotwired/stimulus'

/*
 * Make labels and other elements fire click event when Enter or
 * Spacebar are pressed while focused.
 */
export default class extends Controller {
  keydown (event) {
    if (![13, 32].includes(event.keyCode)) return;

    this.element.click()
  }
}
