import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["media"];

  async pause(event) {
    if (!this.hasMediaTarget) return;

    try {
      await this.mediaTarget.pause();
    } catch(e) {
      console.log(e);
    }
  }
}
